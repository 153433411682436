<template>
    <b-modal
        v-model="config.isShowModal"
        size="xl"
        no-close-on-backdrop
        id="box3"
        title="プレビュー"
        scrollable
        hide-footer
        @hidden="handleCloseModal"
    >
        <div style="height: 100vh; position: relative">
            <b-tabs justified nav-class="nav-tabs-custom" content-class="p-3 text-muted" v-if="!config.isLoadingPreviewFAQ">
                <div v-for="(item, k) in config.listFAQ" :key="k + 'SS'">
                    <b-tab class="border-0">
                        <template v-slot:title>
                            <span class="">{{ k }}</span>
                        </template>
                        <div style="min-height: 700px">
                            <template v-for="(te, j) in item">
                                <div class="style1" v-if="(j == 3 || j == 5 || j == 6) && k == '電話事務局'" :key="j">
                                    <pre>{{ te }}</pre>
                                </div>
                                <div class="style1" v-else-if="(j == 3 || j == 5 || j == 6 || j == 7) && k == 'メール事務局'" :key="j">
                                    <pre>{{ te }}</pre>
                                </div>
                                <pre v-else :key="j">{{ te }}</pre>
                            </template>
                        </div>
                    </b-tab>
                </div>
                <div v-for="tabs in tab" :key="tabs">
                    <div v-for="(item, k) in config.listData" :key="k">
                        <b-tab class="border-0" v-if="tabs == k">
                            <template v-slot:title>
                                <span class="">{{ k }}</span>
                            </template>
                            <div style="min-height: 700px">
                                <div class="table-responsive">
                                    <table class="table table-bordered box6">
                                        <tr>
                                            <th>Q</th>
                                            <th v-for="tit in config.listTitle[k]" :key="tit">
                                                {{ tit }}
                                            </th>
                                        </tr>
                                        <tbody>
                                            <tr v-for="(jtem, ky) in item" :key="jtem.id">
                                                <td style="position: relative">
                                                    <textarea
                                                        :disabled="!showDataQ[jtem.id]"
                                                        style="border: 0; min-width: 320px"
                                                        type="text"
                                                        rows="4"
                                                        class="form-control"
                                                        v-model="jtem.question"
                                                    ></textarea>
                                                    <i
                                                        style="position: absolute; z-index: 999; right: 25px; top: 5px"
                                                        v-if="showDataQ[jtem.id]"
                                                        @click="updateDataQ(jtem, jtem.id)"
                                                        class="fa fa-save text-success"
                                                    ></i>
                                                    <i
                                                        style="position: absolute; z-index: 999; right: 25px; top: 5px"
                                                        v-else
                                                        @click="setDataQ(jtem.id)"
                                                        class="fa fa-pencil-alt text-primary"
                                                    ></i>
                                                    <i
                                                        style="position: absolute; right: 5px; top: 5px"
                                                        @click="deleteDataQuestion(item, jtem, ky)"
                                                        class="fa fa-trash text-danger"
                                                    ></i>
                                                </td>
                                                <th v-for="(tit, k) in config.listTitle[k]" :key="tit + 'u'" class="checkHR">
                                                    <div v-for="(aS, key) in jtem.answers" :key="aS.id">
                                                        <div v-if="aS.category_id == k" style="position: relative">
                                                            <div class="d-flex align-items-center">
                                                                <textarea
                                                                    style="border: 0; min-width: 320px"
                                                                    :disabled="!showData[aS.id]"
                                                                    type="text"
                                                                    rows="4"
                                                                    class="form-control"
                                                                    v-model="aS.answer"
                                                                ></textarea>
                                                                <i
                                                                    style="position: absolute; z-index: 999; right: 20px; top: -8px"
                                                                    v-if="showData[aS.id]"
                                                                    @click="updateData(jtem, aS.id)"
                                                                    class="fa fa-save text-success"
                                                                ></i>
                                                                <i
                                                                    style="position: absolute; z-index: 999; right: 20px; top: -8px"
                                                                    v-else
                                                                    @click="setData(aS.id)"
                                                                    class="fa fa-pencil-alt text-primary"
                                                                ></i>
                                                                <i
                                                                    style="position: absolute; z-index: 999; right: 0px; top: -8px"
                                                                    @click="deleteData(jtem, key)"
                                                                    class="fa fa-trash text-danger"
                                                                ></i>
                                                            </div>
                                                            <hr />
                                                        </div>
                                                    </div>
                                                </th>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </b-tab>
                    </div>
                </div>
            </b-tabs>
            <div v-else style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%)">
                <LoadingIcon />
            </div>
        </div>
    </b-modal>
</template>

<script>
import { masterMethods } from '@/state/helpers';
import LoadingIcon from '@/components/Loading/main.vue';

export default {
    name: 'PopUpFAQ',
    props: {
        config: {
            type: Object,
            required: false
        }
    },
    components: {
        LoadingIcon
    },
    data() {
        return {
            initForm: {},
            showData: {},
            showDataQ: {},
            listData: [],
            listDataType1: [],
            listDataType2: [],
            listDataPlatform: [],
            tab: [
                '電話事務局',
                'メール事務局',
                'キャンペーン全般',
                '抽選',
                '抽選（インスタントウィン）',
                '賞品',
                '高額賞品',
                '高額賞金',
                'キャッシュバック',
                '賞品（ｲﾍﾞﾝﾄ）',
                '賞品（OLｲﾍﾞﾝﾄ）',
                'web応募F',
                'CRsｼｽ',
                '当選フォーム',
                '個人情報の取り扱い',
                'クレーム対応',
                'その他'
            ],
            customFormatDate: 'YYYY-MM-DD'
        };
    },
    methods: {
        ...masterMethods,

        handleClearData() {},
        deleteData(object, key) {
            object.answers.splice(key, 1);
            this.updateDocumentFAQ(object).then((data) => {
                if (data.code == 200) {
                    // this.$bvToast.toast(`warning`, {
                    //     title: ` 削除されました。 `,
                    //     variant: "success",
                    //     toaster: "b-toaster-top-center",
                    //     solid: true,
                    // });
                    this.config.listData = JSON.parse(JSON.stringify(this.config.listData));
                }
            });
        },
        deleteDataQuestion(object, data, key) {
            console.log(object);
            this.deleteDocumentFAQ(data).then((data) => {
                if (data.code == 200) {
                    // this.$bvToast.toast(`warning`, {
                    //     title: ` 削除されました。 `,
                    //     variant: "success",
                    //     toaster: "b-toaster-top-center",
                    //     solid: true,
                    // });
                    delete object[key];
                    this.config.listData = JSON.parse(JSON.stringify(this.config.listData));
                }
            });
        },
        updateData(object, key) {
            this.updateDocumentFAQ(object).then((data) => {
                console.log(data);
                if (data.code == 200) {
                    // this.$bvToast.toast(`warning`, {
                    //   title: `更新されました。`,
                    //   variant: "success",
                    //   toaster: "b-toaster-top-center",
                    //   solid: true,
                    // });
                    this.showData[key] = false;
                    this.showData = JSON.parse(JSON.stringify(this.showData));
                }
            });
        },
        updateDataQ(object, key) {
            this.updateDocumentFAQ(object).then((data) => {
                console.log(data);
                if (data.code == 200) {
                    // this.$bvToast.toast(`warning`, {
                    //   title: `更新されました。`,
                    //   variant: "success",
                    //   toaster: "b-toaster-top-center",
                    //   solid: true,
                    // });
                    this.showDataQ[key] = false;
                    this.showData = JSON.parse(JSON.stringify(this.showData));
                }
            });
        },
        setData(key) {
            this.showData[key] = true;
            this.showData = JSON.parse(JSON.stringify(this.showData));
        },
        setDataQ(key) {
            this.showDataQ[key] = true;
            this.showDataQ = JSON.parse(JSON.stringify(this.showDataQ));
        },

        handleChangeTypeApplication(id) {
            this.handleDataCommon('change', id);
        },

        handleCloseModal() {
            this.$props.config.isShowModal = false;
            this.handleClearData();
        },

        handleCheckedApplicationType1() {
            this.listDataType1.forEach((parent) => {
                parent.works.forEach((child) => {
                    child.checked = this.config.listIDWorks.includes(child.id);
                });
            });
        },

        handleSubmit() {
            // this.$emit("update:exportSchedule", objSchedule);
            this.handleCloseModal();
        }
    },
    watch: {
        config: {
            handler() {},
            deep: true
        }
    },
    mounted() {}
};
</script>

<style lang="scss" scoped></style>
