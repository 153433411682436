<template>
    <b-modal
        v-model="config.isShowModal"
        size="xl"
        no-close-on-backdrop
        :id="config.id"
        :title="$t('sheduleFile.popUpSchedule')"
        scrollable
        hide-footer
        @hidden="handleCloseModal"
    >
        <div>
            <form class="needs-validation" autocomplete="off" @submit.prevent="handleSubmit" @keydown.enter.prevent>
                <div class="form-group row mb-3">
                    <label class="col-sm-3">{{ $t('sheduleFile.startDateCP') }} <span class="text-danger">*</span></label>
                    <div class="col-sm-9 pl-0">
                        <DatePickerCommon
                            :year.sync="initForm.start_date_cp_year"
                            :month.sync="initForm.start_date_cp_month"
                            :day.sync="initForm.start_date_cp_day"
                            :full_date.sync="initForm.start_date_cp_full_date"
                            :config.sync="initForm.configStartDate"
                        >
                            <div v-if="initForm.errorStartDateCP" class="invalid-feedback d-block">
                                <span>{{ initForm.errorStartDateCP }}</span>
                            </div>
                        </DatePickerCommon>
                    </div>
                </div>

                <div class="form-group row mb-3">
                    <label class="col-sm-3">{{ $t('sheduleFile.endDateCP') }} <span class="text-danger">*</span></label>
                    <div class="col-sm-9 pl-0">
                        <DatePickerCommon
                            :year.sync="initForm.end_date_cp_year"
                            :month.sync="initForm.end_date_cp_month"
                            :day.sync="initForm.end_date_cp_day"
                            :full_date.sync="initForm.end_date_cp_full_date"
                            :config.sync="initForm.configEndDate"
                        >
                            <div v-if="initForm.errorEndDateCP" class="invalid-feedback d-block">
                                <span>{{ initForm.errorEndDateCP }}</span>
                            </div>
                        </DatePickerCommon>
                    </div>
                </div>

                <div class="form-group row mb-3">
                    <label class="col-sm-3">{{ $t('sheduleFile.dateShipping') }} <span class="text-danger">*</span></label>
                    <div class="col-sm-9 pl-0">
                        <DatePickerCommon
                            :year.sync="initForm.date_shipping_year"
                            :month.sync="initForm.date_shipping_month"
                            :day.sync="initForm.date_shipping_day"
                            :full_date.sync="initForm.date_shipping_full_date"
                            :config.sync="initForm.configDateShipping"
                        >
                            <div v-if="initForm.errorDateShipping" class="invalid-feedback d-block">
                                <span>{{ initForm.errorDateShipping }}</span>
                            </div>
                        </DatePickerCommon>
                    </div>
                </div>

                <div class="form-group row mb-3">
                    <label class="col-sm-3">{{ $t('sheduleFile.endDateSecretariat') }} <span class="text-danger">*</span></label>
                    <div class="col-sm-9 pl-0">
                        <DatePickerCommon
                            :year.sync="initForm.end_date_secretariat_year"
                            :month.sync="initForm.end_date_secretariat_month"
                            :day.sync="initForm.end_date_secretariat_day"
                            :full_date.sync="initForm.end_date_secretariat_full_date"
                            :config.sync="initForm.configEndDateSecretariat"
                        >
                            <div v-if="initForm.errorEndDateSecretariat" class="invalid-feedback d-block">
                                <span>{{ initForm.errorEndDateSecretariat }}</span>
                            </div>
                        </DatePickerCommon>
                    </div>
                </div>

                <div class="form-group row mb-3">
                    <label class="col-sm-3">{{ $t('sheduleFile.typeApplication') }}</label>
                    <div class="col-sm-9 d-flex flex-wrap">
                        <div
                            v-for="item in initForm.listApplication"
                            :key="item.id + 's'"
                            class="custom-control custom-checkbox mr-4 mb-3"
                            style="min-width: fit-content"
                        >
                            <input
                                type="checkbox"
                                v-model="initForm.type_application"
                                :id="`check${item.id}`"
                                :value="item.id"
                                class="custom-control-input"
                                @change="handleChangeTypeApplication(item.id)"
                            />
                            <label :for="`check${item.id}`" class="custom-control-label">{{ item.value }}</label>
                        </div>
                    </div>
                </div>

                <div class="form-group row mb-3">
                    <div class="table-responsive" style="height: 330px; overflow-y: auto">
                        <table class="table mb-0 table-bordered">
                            <thead>
                                <tr>
                                    <th colspan="1" rowspan="3">
                                        {{ $t('sheduleFile.conditionExport') }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="(itemT1, indexT1) in listDataType1">
                                    <tr v-for="(ws, idx) in itemT1.works" :key="`${indexT1}-${idx}-type1`">
                                        <td v-if="idx === 0" :rowspan="itemT1.works.length">
                                            {{ itemT1.title }}
                                        </td>
                                        <td>
                                            <div class="row-data-small">
                                                {{ ws.work_title ? ws.work_title : '' }}
                                            </div>
                                        </td>
                                        <td>
                                            <div class="row-data-small">
                                                <input
                                                    type="checkbox"
                                                    v-model="ws.checked"
                                                    :id="`${indexT1}-${idx}`"
                                                    style="cursor: pointer; width: 16px; height: 16px"
                                                    :value="ws.checked"
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                </template>

                                <template v-for="(itemT2, indexT2) in listDataType2">
                                    <tr v-for="(ws, idx) in itemT2.works" :key="`${indexT2}-${idx}-type2`">
                                        <td v-if="idx === 0" :rowspan="itemT2.works.length">
                                            {{ itemT2.title }}
                                        </td>
                                        <td>
                                            <div class="row-data-small">
                                                {{ ws.work_title ? ws.work_title : '' }}
                                            </div>
                                        </td>
                                        <td>
                                            <div class="row-data-small">
                                                <input
                                                    type="checkbox"
                                                    v-model="ws.checked"
                                                    :id="`${indexT2}-${idx}`"
                                                    :value="ws.checked"
                                                    style="cursor: pointer; width: 16px; height: 16px"
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </div>
            </form>
        </div>

        <div class="d-flex justify-content-center mt-5">
            <div class="text-center flex-fill">
                <button type="button" class="btn btn-light mr-3" @click="handleCloseModal">
                    {{ $t('btn.cancel') }}
                </button>
                <button type="button" class="btn btn-primary ml-3" @click="handleSubmit">
                    {{ $t('btn.save') }}
                </button>
            </div>
        </div>
    </b-modal>
</template>

<script>
import DatePickerCommon from '@/components/Select/date/main.vue';
import { masterMethods } from '@/state/helpers';
import { handleValidate } from './index';
export default {
    name: 'PopUpSchedule',
    props: {
        config: {
            type: Object,
            required: false
        }
    },
    components: {
        DatePickerCommon
    },
    data() {
        return {
            initForm: {
                start_date_cp_year: '',
                start_date_cp_month: '',
                start_date_cp_day: '',
                start_date_cp_full_date: '',
                errorStartDateCP: '',
                configStartDate: {
                    error: false
                },

                end_date_cp_year: '',
                end_date_cp_month: '',
                end_date_cp_day: '',
                end_date_cp_full_date: '',
                errorEndDateCP: '',
                configEndDate: {
                    error: false
                },

                date_shipping_year: '',
                date_shipping_month: '',
                date_shipping_day: '',
                date_shipping_full_date: '',
                errorDateShipping: '',
                configDateShipping: {
                    error: false
                },

                end_date_secretariat_year: '',
                end_date_secretariat_month: '',
                end_date_secretariat_day: '',
                end_date_secretariat_full_date: '',
                errorEndDateSecretariat: '',
                configEndDateSecretariat: {
                    error: false
                },

                type_application: [],
                listApplication: []
            },
            listData: [],
            listDataType1: [],
            listDataType2: [],
            listDataPlatform: [],
            customFormatDate: 'YYYY-MM-DD'
        };
    },
    methods: {
        ...masterMethods,

        handleClearData() {
            this.initForm = {
                start_date_cp_year: '',
                start_date_cp_month: '',
                start_date_cp_day: '',
                start_date_cp_full_date: '',
                errorStartDateCP: '',
                configStartDate: {
                    error: false
                },

                end_date_cp_year: '',
                end_date_cp_month: '',
                end_date_cp_day: '',
                end_date_cp_full_date: '',
                errorEndDateCP: '',
                configEndDate: {
                    error: false
                },

                date_shipping_year: '',
                date_shipping_month: '',
                date_shipping_day: '',
                date_shipping_full_date: '',
                errorDateShipping: '',
                configDateShipping: {
                    error: false
                },

                end_date_secretariat_year: '',
                end_date_secretariat_month: '',
                end_date_secretariat_day: '',
                end_date_secretariat_full_date: '',
                errorEndDateSecretariat: '',
                configEndDateSecretariat: {
                    error: false
                },

                type_application: []
            };
        },

        handleChangeTypeApplication(id) {
            this.handleDataCommon('change', id);
        },

        handleCloseModal() {
            this.$props.config.isShowModal = false;
            this.handleClearData();
        },

        handleCheckedApplicationType1() {
            this.listDataType1.forEach((parent) => {
                parent.works.forEach((child) => {
                    child.checked = this.config.listIDWorks.includes(child.id);
                });
            });
        },

        handleDataCommon(type, id) {
            this.listDataType2 = [];
            this.listDataPlatform.forEach((parent) => {
                parent.platforms.forEach((child) => {
                    if (id && !this.initForm.type_application.includes(id)) {
                        if (child.id === id) {
                            const mapData = parent.platforms.map((p) => p.id);
                            const isSome = mapData.some((item) => this.initForm.type_application.includes(item));
                            if (isSome) return;
                            parent.works.forEach((ws) => {
                                ws.checked = false;
                            });
                        }
                    }

                    if (this.initForm.type_application.includes(child.id)) {
                        if (!this.listDataType2.some((item) => item.id === parent.id)) {
                            if (type !== 'change') {
                                parent.works.forEach((ws) => {
                                    ws.checked = this.config.listIDWorks.includes(ws.id);
                                });
                            }
                            this.listDataType2.push(parent);
                        }
                    }
                });
            });
        },

        handleSubmit() {
            let check = handleValidate(this.initForm);
            if (!check) return;
            const checkedType1 = this.listDataType1.map((item) => item.works.filter((ws) => ws.checked).map((ws) => ws.id));
            const checkedType2 = this.listDataType2.map((item) => item.works.filter((ws) => ws.checked).map((ws) => ws.id));

            let objSchedule = {
                parentID: this.config.code,
                titles: [...checkedType1, ...checkedType2].flat(),
                campaign_start_date: this.initForm.start_date_cp_full_date,
                campaign_end_date: this.initForm.end_date_cp_full_date,
                date_shipping: this.initForm.date_shipping_full_date,
                secretariat_end_date: this.initForm.end_date_secretariat_full_date,
                categories: this.initForm.type_application.map((item) => {
                    return {
                        category_id: item
                    };
                })
            };
            this.$emit('update:exportSchedule', objSchedule);
            this.handleCloseModal();
        },

        getListMasterScheduleFile() {
            this.getListScheduleFile()
                .then((dataRespone) => {
                    this.listDataType1 = dataRespone.data.filter((item) => {
                        if (item.type === 1) {
                            item.works.forEach((ws) => {
                                ws.checked = false;
                            });
                            return item;
                        }
                    });
                    this.listDataPlatform = dataRespone.data.filter((item) => {
                        if (item.type === 2) {
                            item.works.forEach((ws) => {
                                ws.checked = false;
                            });
                            return item;
                        }
                    });
                })
                .catch((error) => {
                    console.log('error', error);
                });
        }
    },
    watch: {
        config: {
            handler() {
                let handleData = this.config.dataCPType.find((item) => {
                    return item.id === this.config.dataDocument.campaign_type.id;
                });
                this.initForm = {
                    ...this.initForm,
                    start_date_cp_full_date: this.config.dataDocument.campaign_start_date ?? '',
                    end_date_cp_full_date: this.config.dataDocument.campaign_end_date ?? '',
                    date_shipping_full_date: this.config.dateShipping ?? '',
                    end_date_secretariat_full_date: this.config.dataDocument.secretariat_end_date ?? '',

                    listApplication: handleData.data.map((item) => {
                        return {
                            id: item.id,
                            value: item.value
                        };
                    }),
                    type_application: this.config.dataDocument.categories.map((item) => item.category_id)
                };
                this.handleCheckedApplicationType1();
                this.handleDataCommon('config', null);
            },
            deep: true
        }
    },
    mounted() {
        this.getListMasterScheduleFile();
    }
};
</script>

<style lang="scss" scoped></style>
