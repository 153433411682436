import i18n from '@/i18n';
import { isValidDate } from '@/state/helpers';

function handleIsValidDate(day, month, year) {
    return isValidDate(day + '/' + month + '/' + year);
}

function handleValidate(initForm) {
    let check = true;
    // start-date-cp
    if (initForm.start_date_cp_day === '' || initForm.start_date_cp_month === '' || initForm.start_date_cp_year === '') {
        initForm.errorStartDateCP = i18n.t('validateField.required', {
            field: i18n.t('sheduleFile.startDateCP')
        });
        initForm.configStartDate.error = true;
        check = false;
    } else {
        let resultCheck = handleIsValidDate(initForm.start_date_cp_day, initForm.start_date_cp_month, initForm.start_date_cp_year);
        if (!resultCheck) {
            initForm.errorStartDateCP = i18n.t('validateField.incorrectFormat', {
                field: i18n.t('sheduleFile.startDateCP')
            });
            check = false;
            initForm.configStartDate.error = true;
        } else {
            initForm.errorStartDateCP = '';
            initForm.configStartDate.error = false;
        }
    }

    // end-date-cp

    if (initForm.end_date_cp_day === '' || initForm.end_date_cp_month === '' || initForm.end_date_cp_year === '') {
        initForm.errorEndDateCP = i18n.t('validateField.required', {
            field: i18n.t('sheduleFile.endDateCP')
        });
        check = false;
        initForm.configEndDate.error = true;
    } else {
        let resultCheck = handleIsValidDate(initForm.end_date_cp_day, initForm.end_date_cp_month, initForm.end_date_cp_year);
        if (!resultCheck) {
            initForm.errorEndDateCP = i18n.t('validateField.incorrectFormat', {
                field: i18n.t('sheduleFile.endDateCP')
            });
            check = false;
            initForm.configEndDate.error = true;
        } else {
            if (handleIsValidDate(initForm.start_date_cp_day, initForm.start_date_cp_month, initForm.start_date_cp_year)) {
                const startDateObj = new Date(
                    Number(initForm.start_date_cp_year) + '/' + Number(initForm.start_date_cp_month) + '/' + Number(initForm.start_date_cp_day)
                );

                const endDateObj = new Date(
                    Number(initForm.end_date_cp_year) + '/' + Number(initForm.end_date_cp_month) + '/' + Number(initForm.end_date_cp_day)
                );
                if (startDateObj.getTime() > endDateObj.getTime()) {
                    initForm.errorEndDateCP = i18n.t('validateField.compareDate');
                    check = false;
                    initForm.configEndDate.error = true;
                } else {
                    initForm.errorEndDateCP = '';
                    initForm.configEndDate.error = false;
                }
            } else {
                initForm.errorEndDateCP = '';
                initForm.configEndDate.error = false;
            }
        }
    }

    // date_shipping
    if (initForm.date_shipping_day === '' || initForm.date_shipping_month === '' || initForm.date_shipping_year === '') {
        initForm.errorDateShipping = i18n.t('validateField.required', {
            field: i18n.t('sheduleFile.dateShipping')
        });
        check = false;
        initForm.configDateShipping.error = true;
    } else {
        let resultCheck = handleIsValidDate(initForm.date_shipping_day, initForm.date_shipping_month, initForm.date_shipping_year);
        if (!resultCheck) {
            initForm.errorDateShipping = i18n.t('validateField.incorrectFormat', {
                field: i18n.t('sheduleFile.dateShipping')
            });
            check = false;
            initForm.configDateShipping.error = true;
        } else {
            initForm.errorDateShipping = '';
            initForm.configDateShipping.error = false;
        }
    }

    // end_date_secretariat
    if (initForm.end_date_secretariat_day === '' || initForm.end_date_secretariat_month === '' || initForm.end_date_secretariat_year === '') {
        initForm.errorEndDateSecretariat = i18n.t('validateField.required', {
            field: i18n.t('sheduleFile.endDateSecretariat')
        });
        check = false;
        initForm.configEndDateSecretariat.error = true;
    } else {
        let resultCheck = handleIsValidDate(
            initForm.end_date_secretariat_day,
            initForm.end_date_secretariat_month,
            initForm.end_date_secretariat_year
        );
        if (!resultCheck) {
            initForm.errorEndDateSecretariat = i18n.t('validateField.incorrectFormat', {
                field: i18n.t('sheduleFile.endDateSecretariat')
            });
            check = false;
            initForm.configEndDateSecretariat.error = true;
        } else {
            initForm.errorEndDateSecretariat = '';
            initForm.configEndDateSecretariat.error = false;
        }
    }

    return check;
}

export { handleValidate };
