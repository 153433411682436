<template>
    <Layout>
        <div class="row form__body--card form__common--center" v-if="!isLoading">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body" style="overflow-x: auto">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="row flex-nowrap justify-content-around">
                                    <div class="col-sm-4" style="min-width: max-content">
                                        <!-- show wl default -->
                                        <div v-if="!workflowTypeRegistered">
                                            <div
                                                v-for="(headItem, indexHead) in dataWorkflowConfig"
                                                :key="indexHead"
                                                class="rounded px-1"
                                                :style="{
                                                    'border-left': activeStepCurrent !== headItem.child_id ? '' : '9px solid red',
                                                    'padding-left': activeStepCurrent !== headItem.child_id ? '' : '9px'
                                                }"
                                            >
                                                <div
                                                    class="p-2 my-1 d-flex justify-content-between align-items-center border-bottom rounded"
                                                    :style="`background-color:${headItem.backgroundColor}`"
                                                >
                                                    <p class="mb-0" style="min-width: 320px">{{ headItem.label }}</p>
                                                    <div>
                                                        <button
                                                            type="button"
                                                            class="btn btn-secondary mx-3"
                                                            :class="
                                                                !headItem.confirmed_by || headItem.data_chat.length === 0 ? 'disabled_cursor' : ''
                                                            "
                                                            style="min-width: fit-content"
                                                            :disabled="!headItem.confirmed_by || headItem.data_chat.length === 0"
                                                            @click="handleGetHistory(null, headItem)"
                                                        >
                                                            <i class="fa fa-history"></i>
                                                        </button>
                                                        <button
                                                            type="button"
                                                            class="btn btn-success"
                                                            :class="headItem.isDisabledStep ? 'disabled_cursor disable-step' : ''"
                                                            style="min-width: fit-content"
                                                            :disabled="headItem.isDisabledStep"
                                                            @click="handleOpenModal(null, headItem)"
                                                        >
                                                            {{ $t('btn.ok') }}
                                                        </button>
                                                    </div>
                                                </div>

                                                <div class="form-group row p-2" v-if="headItem.status">
                                                    <label class="col-sm-3" for="to">{{ $t('settingPhone.confirmBy') }} </label>
                                                    <div class="col-sm-10">{{ headItem.confirmed_by?.name ?? '' }}</div>
                                                </div>

                                                <div v-if="headItem.data_chat && headItem.confirmed_by">
                                                    <div v-for="(item, index) in headItem.data_chat" :key="index">
                                                        <div class="form-group row p-2">
                                                            <label class="col-sm-3" for="to">{{ $t('settingPhone.popUpSectionWork.to') }} </label>
                                                            <div class="col-sm-9" style="max-width: 435px">
                                                                <MultiSelectCommon
                                                                    :id="`multi-select-after-send-to-${index}`"
                                                                    :value.sync="item.afterSendTo"
                                                                    :options="optionListUserAfterSendTo"
                                                                    :config.sync="configListUserAfterSendTo"
                                                                    :disabled="true"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <p class="text-center text-danger" v-if="isShowWarning">
                                                {{ $t('settingPhone.warningRegisterSetting') }}
                                            </p>
                                        </div>

                                        <!-- show wl internal or partner -->
                                        <div v-else>
                                            <div class="px-3">
                                                <div
                                                    v-for="(headItem, indexHead) in dataWorkflowConfig"
                                                    :key="indexHead"
                                                    class="rounded px-1"
                                                    :style="{
                                                        'border-left': activeStepCurrent !== headItem.child_id ? '' : '9px solid red',
                                                        'padding-left': activeStepCurrent !== headItem.child_id ? '' : '9px'
                                                    }"
                                                >
                                                    <div
                                                        class="p-2 my-1 d-flex justify-content-between align-items-center border-bottom rounded"
                                                        :style="`background-color:${headItem.backgroundColor}`"
                                                    >
                                                        <p class="mb-0" style="min-width: 320px">{{ headItem.label }}</p>
                                                        <div>
                                                            <button
                                                                type="button"
                                                                class="btn btn-secondary mx-3"
                                                                :class="
                                                                    !headItem.confirmed_by || headItem.data_chat.length === 0 ? 'disabled_cursor' : ''
                                                                "
                                                                style="min-width: fit-content"
                                                                :disabled="!headItem.confirmed_by || headItem.data_chat.length === 0"
                                                                @click="handleGetHistory(null, headItem)"
                                                            >
                                                                <i class="fa fa-history"></i>
                                                            </button>
                                                            <button
                                                                type="button"
                                                                class="btn btn-success"
                                                                :class="headItem.isDisabledStep ? 'disabled_cursor disable-step' : ''"
                                                                style="min-width: fit-content"
                                                                :disabled="headItem.isDisabledStep"
                                                                @click="handleOpenModal(null, headItem)"
                                                            >
                                                                {{ $t('btn.ok') }}
                                                            </button>
                                                        </div>
                                                    </div>

                                                    <div class="form-group row p-2" v-if="headItem.status">
                                                        <label class="col-sm-3" for="to">{{ $t('settingPhone.confirmBy') }} </label>
                                                        <div class="col-sm-10">{{ headItem.confirmed_by?.name ?? '' }}</div>
                                                    </div>

                                                    <div v-if="headItem.data_chat && headItem.confirmed_by">
                                                        <div v-for="(item, index) in headItem.data_chat" :key="index">
                                                            <div class="form-group row p-2">
                                                                <label class="col-sm-3" for="to">{{ $t('settingPhone.popUpSectionWork.to') }} </label>
                                                                <div class="col-sm-9" style="max-width: 435px">
                                                                    <MultiSelectCommon
                                                                        :id="`multi-select-after-send-to-${index}`"
                                                                        :value.sync="item.afterSendTo"
                                                                        :options="optionListUserAfterSendTo"
                                                                        :config.sync="configListUserAfterSendTo"
                                                                        :disabled="true"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <b-tabs
                                                v-if="isShowWorkflowTypeAfterStep2Done"
                                                justified
                                                nav-class="nav-tabs-custom"
                                                content-class="p-3 text-muted"
                                                v-model="activeTabFollowStep"
                                            >
                                                <b-tab class="border-0" v-for="(stp, idx) in settingPhoneWork" :key="idx">
                                                    <template v-slot:title>
                                                        <p class="mb-0 font-weight-bold">
                                                            {{ stp.label }}
                                                        </p>
                                                    </template>

                                                    <template>
                                                        <div
                                                            v-for="(headItem, indexHead) in stp.headWork"
                                                            :key="indexHead"
                                                            class="rounded px-1"
                                                            :style="{
                                                                'border-left': activeStepCurrent !== headItem.child_id ? '' : '9px solid red',
                                                                'padding-left': activeStepCurrent !== headItem.child_id ? '' : '9px'
                                                            }"
                                                        >
                                                            <div
                                                                class="p-2 my-1 d-flex justify-content-between align-items-center border-bottom rounded"
                                                                :style="`background-color:${headItem.backgroundColor}`"
                                                            >
                                                                <p class="mb-0" style="min-width: 320px">
                                                                    {{ headItem.label }}
                                                                </p>
                                                                <div>
                                                                    <button
                                                                        type="button"
                                                                        class="btn btn-secondary mx-3"
                                                                        :class="
                                                                            !headItem.confirmed_by || headItem.data_chat.length === 0
                                                                                ? 'disabled_cursor'
                                                                                : ''
                                                                        "
                                                                        style="min-width: fit-content"
                                                                        :disabled="!headItem.confirmed_by || headItem.data_chat.length === 0"
                                                                        @click="handleGetHistory(stp, headItem)"
                                                                    >
                                                                        <i class="fa fa-history"></i>
                                                                    </button>
                                                                    <button
                                                                        type="button"
                                                                        class="btn btn-success"
                                                                        :class="headItem.isDisabledStep ? 'disabled_cursor disable-step' : ''"
                                                                        style="min-width: fit-content"
                                                                        :disabled="headItem.isDisabledStep"
                                                                        @click="handleOpenModal(stp, headItem)"
                                                                    >
                                                                        {{ $t('btn.ok') }}
                                                                    </button>
                                                                </div>
                                                            </div>

                                                            <div class="form-group row p-2" v-if="headItem.status">
                                                                <label class="col-sm-3" for="to">{{ $t('settingPhone.confirmBy') }} </label>
                                                                <div class="col-sm-10">{{ headItem.confirmed_by?.name ?? '' }}</div>
                                                            </div>

                                                            <div v-if="headItem.data_chat && headItem.confirmed_by">
                                                                <div v-for="(item, index) in headItem.data_chat" :key="index">
                                                                    <div class="form-group row p-2">
                                                                        <label class="col-sm-3" for="to"
                                                                            >{{ $t('settingPhone.popUpSectionWork.to') }}
                                                                        </label>
                                                                        <div class="col-sm-9" style="max-width: 435px">
                                                                            <MultiSelectCommon
                                                                                :id="`multi-select-after-send-to-${index}`"
                                                                                :value.sync="item.afterSendTo"
                                                                                :options="optionListUserAfterSendTo"
                                                                                :config.sync="configListUserAfterSendTo"
                                                                                :disabled="true"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </template>
                                                </b-tab>
                                            </b-tabs>
                                        </div>
                                    </div>

                                    <div :class="`col-sm-8`">
                                        <div class="border rounded p-2">
                                            <form class="needs-validation" @submit.prevent="handleSubmit" autocomplete="off">
                                                <StartOne
                                                    :data="initForm.startOne"
                                                    :disabled="isEdit"
                                                    :disabledField="{ stepSuccessDisabledForUserCP, noEditTypeWorkflow, testCall }"
                                                    :isSeenFirst="isSeenFirst"
                                                    @updateData="
                                                        (dataEmitStartOne) => {
                                                            newDataStartOne = dataEmitStartOne;
                                                        }
                                                    "
                                                />
                                                <hr />
                                                <StartFive
                                                    :data="initForm.startFive"
                                                    :disabled="isEdit"
                                                    :isSeenFirst="isSeenFirst"
                                                    @updateData="
                                                        (dataEmitStartFive) => {
                                                            newDataStartFive = dataEmitStartFive;
                                                        }
                                                    "
                                                />
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer>
                <div class="d-flex align-items-center">
                    <div
                        v-for="(itemAction, index) in actionType"
                        :key="index"
                        class="rounded mx-3 px-3"
                        :style="`background-color:${itemAction.backgroundColor}; min-width: fit-content;`"
                    >
                        <p class="mb-0 py-2" style="white-space: nowrap">
                            {{ itemAction.label }}
                        </p>
                    </div>

                    <div class="col-sm-6">
                        <div class="text-center">
                            <button
                                type="button"
                                class="btn btn-light"
                                @click="
                                    $router.push({
                                        path: 'campaign/form/view',
                                        query: {
                                            id: $route.query.id
                                        }
                                    })
                                "
                            >
                                {{ $t('btn.cancel') }}
                            </button>
                            <button
                                type="submit"
                                class="btn btn-primary ml-3"
                                :class="{
                                    disabled_cursor: isEdit
                                }"
                                style="white-space: nowrap"
                                :disabled="isEdit"
                                @click="handleSubmit"
                            >
                                {{ $t('btn.save') }}
                            </button>
                        </div>
                    </div>
                </div>
            </Footer>

            <PopUpSectionWorkPhone :config="configSectionWorkPhone" @refreshData="handleRefreshData" />
        </div>

        <div class="d-flex justify-content-center" v-else>
            <LoadingIcon />
        </div>
    </Layout>
</template>

<script>
import i18n from '@/i18n';
import Layout from '@/views/layouts/main';
import appConfig from '@/app.config';
import StartOne from '@/views/pages/documents/settingPhone/common/components/startOne/main';
import StartFive from '@/views/pages/documents/settingPhone/common/components/startFive/main';
import PopUpSectionWorkPhone from '@/views/pages/documents/settingPhone/components/popup/index.vue';
import MultiSelectCommon from '@/components/Select/multi/main.vue';
import LoadingIcon from '@/components/Loading/main.vue';
import Footer from '@/components/Footer/main.vue';
import { CONFIG_LIST_USER_AFTER_SEND_TO, ACTION_TYPE, STATUS_WORKFLOW, WORKFLOW_TYPE } from '@/views/pages/documents/settingPhone/constants';
import { settingPhoneMethods, authComputed, masterMethods } from '@/state/helpers';
import {
    handleDisabledStep,
    handleMapDataInitForm,
    FORM_INPUT_COMMON,
    SETTING_PHONE_WORK_COMMON,
    handleSetConfigTemplate,
    setDataConfigForDocument
} from '@/views/pages/documents/settingPhone/index';
import { state } from '@/state/modules/authfack.js';
import { showMessage } from '@/utils/messages.js';
import { handleValidate } from '@/views/pages/documents/settingPhone/common/components/startOne/index';

export default {
    page: {
        title: i18n.t('settingPhone.namePage'),
        meta: [{ name: 'description', content: appConfig.description }]
    },
    name: 'SettingPhoneCommon',
    components: {
        Layout,
        StartOne,
        StartFive,
        MultiSelectCommon,
        LoadingIcon,
        PopUpSectionWorkPhone,
        Footer
    },
    props: {
        data: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            initForm: FORM_INPUT_COMMON(),
            settingPhoneWork: SETTING_PHONE_WORK_COMMON(),
            newDataStartOne: null,
            newDataStartFive: null,
            actionType: ACTION_TYPE,
            isEdit: false,
            isRegister: false,
            objectSeen: null,
            isSeenFirst: false,
            isLoading: false,
            configSectionWorkPhone: {
                isShowModal: false,
                code: '',
                dataWork: null
            },
            optionListUserAfterSendTo: [],
            configListUserAfterSendTo: CONFIG_LIST_USER_AFTER_SEND_TO,
            dataWorkflowLocal: [],
            dataWorkflowConfig: [],
            activeStepCurrent: null,
            stepSuccessDisabledForUserCP: false,
            idPhoneSetting: null,
            activeTabFollowStep: 0,
            workflowTypeRegistered: null,
            noEditTypeWorkflow: false,
            testCall: false,
            isShowWarning: false,
            isShowWorkflowTypeAfterStep2Done: false
        };
    },
    computed: {
        ...authComputed
    },
    methods: {
        ...settingPhoneMethods,
        ...masterMethods,

        handleOpenModal(objParent, objChild) {
            if (objChild?.phoneNumberRequired) {
                showMessage(8080, this.$bvToast, this.$t('settingPhone.popUpSectionWork.messPhoneRequired'));
                return;
            }

            let obj = {
                id_phone_setting: this.initForm.startOne.id_phone_setting,
                workflow_type: this.workflowTypeRegistered,
                parent_id: objParent?.parent_id,
                person_in_charge: this.initForm.startOne.person_in_charge,
                ...objChild
            };
            if (obj?.data_chat?.length > 0) {
                let getFirstDataChat = obj.data_chat.filter((item) => item.afterSendTo.length === 0);
                obj.data_chat = getFirstDataChat[0];
            } else {
                obj.data_chat = null;
            }
            this.configSectionWorkPhone.dataWork = obj;
            this.configSectionWorkPhone.code = obj.code;
            this.configSectionWorkPhone.isShowModal = true;
            obj.history = false;
        },

        handleGetHistory(objParent, objChild) {
            let obj = {
                id_phone_setting: this.initForm.startOne.id_phone_setting,
                workflow_type: this.workflowTypeRegistered,
                parent_id: objParent?.parent_id ?? null,
                ...objChild
            };
            obj.history = true;
            obj.data_chat = obj.data_chat[0];
            this.configSectionWorkPhone.dataWork = obj;
            this.configSectionWorkPhone.code = obj.code;
            this.configSectionWorkPhone.isShowModal = true;
        },

        async handleRefreshData() {
            await this.handleGetTemplateCommon();
            await this.handleGetDetailDocument();
        },

        handleUpdate(dataSeen) {
            dataSeen.id = this.idPhoneSetting;
            this.isLoading = true;
            this.updatePhone(dataSeen)
                .then(async (data) => {
                    this.isLoading = false;
                    if (data.code !== 200) {
                        showMessage(data.code, this.$bvToast, data.message);
                        return;
                    }
                    showMessage(data.code, this.$bvToast, '電話申込書が更新されました。');
                    await this.handleGetTemplateCommon();
                    await this.handleGetDetailDocument();
                })
                .catch((err) => {
                    console.log('err', err);
                    this.isLoading = false;
                });
        },

        handleSubmit() {
            let resultValidate = handleValidate(this.newDataStartOne);
            if (!resultValidate.check) return;
            this.objectSeen = {
                workflow_type: this.workflowTypeRegistered,
                ...resultValidate.data,
                ...this.newDataStartFive
            };
            this.handleUpdate(this.objectSeen);
        },

        async getDetailSettingPhone(id, dataProps) {
            this.isLoading = true;
            try {
                const data = await this.detailPhone(id);
                this.handleMapDataRight(dataProps);
                const isChangeWorkflow = data.workflows.find((itemw) => itemw.step === 2);
                if (isChangeWorkflow && isChangeWorkflow.status === STATUS_WORKFLOW[1].id) {
                    this.isShowWorkflowTypeAfterStep2Done = true;
                    await this.handleGetTemplateConfig(data);
                }

                this.isRegister = false;
                this.isEdit = false;
                if (data.phone_setting.workflow_type == WORKFLOW_TYPE.PARTNER) {
                    this.actionType[2].user.name = data.phone_setting.partner.name ?? '';
                }

                this.handleDisabledField(data.workflows);
                let dataReturn = handleMapDataInitForm(this.initForm, data, this.activeStepCurrent, this.dataWorkflowLocal, this.activeTabFollowStep);
                this.activeStepCurrent = dataReturn.activeStepCurrent;
                this.activeTabFollowStep = dataReturn.activeTabFollowStep;

                handleDisabledStep(this.dataWorkflowLocal, this.isRegister, this.isEdit);

                // end workflow
                if (this.dataWorkflowLocal.length > 0 && !this.isShowWarning) {
                    const hasApprovalSuccess = this.dataWorkflowLocal.every((itemHead) => itemHead.status === STATUS_WORKFLOW[1].id);
                    if (hasApprovalSuccess) {
                        this.isRegister = true;
                        this.isEdit = true;
                    }
                }
                this.isLoading = false;
            } catch (error) {
                this.isLoading = false;
                console.log('error', error);
            }
        },

        handleMapDataRight(dataProps) {
            this.initForm.startOne = {
                ...this.initForm.startOne,
                campaign: dataProps.campaign,
                sale_staff: dataProps.sale_staff,
                person_in_charge: dataProps.person_in_charge ?? '',
                campaign_master_order_scopes: dataProps.inforCampaign.campaign_master_order_scopes ?? [],
                campaign_master_order_scope_partner: dataProps.inforCampaign.campaign_master_order_scope_partner ?? [],
                phone_setting_start_time: dataProps.inforCampaign.phone_setting_start_time,
                phone_setting_end_time: dataProps.inforCampaign.phone_setting_end_time,
                phone_setting_start_date: dataProps.inforCampaign.phone_setting_start_date,
                phone_setting_end_date: dataProps.inforCampaign.phone_setting_end_date
            };
        },

        async handleGetDetailDocument() {
            try {
                this.isLoading = true;
                const response = await this.getDetailDocuments({ id: this.$route.query.id });
                let dataRespones = response.data;
                let dataProps = setDataConfigForDocument(dataRespones, this.currentUser);
                this.handleRecivedProps(dataProps);
            } catch (err) {
                console.log('err', err);
                this.isLoading = false;
            }
        },

        async handleGetTemplateConfig(dataPhoneSetting) {
            try {
                if (!this.workflowTypeRegistered) {
                    this.isShowWarning = true;
                    return;
                }
                this.isLoading = true;
                const resTemplate = await this.getTemplateConfig({ workflow_type: this.workflowTypeRegistered });
                const dataRespones = resTemplate.workflow_config ?? null;
                if (!dataRespones) return;
                const dataArray = Object.values(dataRespones);
                if (this.workflowTypeRegistered === WORKFLOW_TYPE.INTERNAL) {
                    this.dataWorkflowLocal = handleSetConfigTemplate(dataArray, this.settingPhoneWork, this.idPhoneSetting, '', dataPhoneSetting)
                        .map((itemSetting) => itemSetting.headWork)
                        .flat();
                    this.dataWorkflowLocal.unshift(...this.dataWorkflowConfig);
                } else {
                    this.dataWorkflowLocal = handleSetConfigTemplate(
                        dataArray,
                        this.settingPhoneWork,
                        this.idPhoneSetting,
                        WORKFLOW_TYPE.PARTNER,
                        dataPhoneSetting
                    )
                        .map((itemSetting) => itemSetting.headWork)
                        .flat();
                    this.dataWorkflowLocal.unshift(...this.dataWorkflowConfig);
                }
                this.isLoading = false;
            } catch (err) {
                this.isLoading = false;
                console.log('err', err);
            }
        },

        async handleGetTemplateCommon() {
            try {
                this.isLoading = true;
                const resTemplateCommon = await this.getTemplateConfig({ workflow_type: WORKFLOW_TYPE.COMMON });
                const dataResponesCommon = resTemplateCommon.workflow_config ?? null;
                if (!dataResponesCommon) return;
                const dataArray = Object.values(dataResponesCommon);
                this.dataWorkflowConfig = handleSetConfigTemplate(dataArray, this.settingPhoneWork, this.idPhoneSetting)
                    .map((itemSetting) => itemSetting.headWork)
                    .flat();
                this.dataWorkflowLocal = this.dataWorkflowConfig;
                this.isLoading = false;
            } catch (err) {
                this.isLoading = false;
                console.log('err', err);
            }
        },

        async handleRecivedProps(dataProps) {
            this.initForm = FORM_INPUT_COMMON();
            this.isEdit = dataProps.isEdit;
            this.isRegister = dataProps.isRegister;
            this.actionType[0].user = dataProps.userRoleDirection ?? [];
            if (state.user.phone_setting_type && state.user.phone_setting_type.length > 0) {
                for (let st of state.user.phone_setting_type) {
                    const foundType = this.actionType.find((item) => item.type === st.type);
                    if (foundType) {
                        foundType.user = [state.user];
                    }
                }
            } else {
                this.actionType = this.actionType.map((item) => {
                    if (item.type === 1 || item.type === 2 || item.type === 3) {
                        item.user = [];
                    }
                    return item;
                });
            }
            this.initForm.inforCampaign = dataProps.inforCampaign;

            this.isSeenFirst = true;
            this.workflowTypeRegistered = dataProps.workflow_type;
            if (!dataProps.phone_setting) {
                if (dataProps.workflow_type === WORKFLOW_TYPE.PARTNER) {
                    await this.handleGetTemplateConfig(null);
                }
                return;
            }
            this.idPhoneSetting = dataProps.phone_setting.id;
            await this.getDetailSettingPhone(this.idPhoneSetting, dataProps);
        },

        handleDisabledField(dataWorkflows) {
            const stepSpecial = {
                fourth: 4,
                five: 5
            };
            dataWorkflows.forEach((item) => {
                if (item.status === STATUS_WORKFLOW[1].id) {
                    if (item.step === stepSpecial.fourth) {
                        this.noEditTypeWorkflow = true;
                    }

                    if (item.step === stepSpecial.five) {
                        this.testCall = true;
                    }
                }
            });
        }
    },
    watch: {
        '$props.data': {
            handler: async function (newData) {
                this.isLoading = true;
                if (newData) {
                    if (newData.campaign && newData.campaign.id) {
                        await this.handleGetTemplateCommon();
                        await this.handleRecivedProps(newData);
                    }
                }
            },
            deep: true,
            immediate: true
        }
    }
};
</script>

<style lang="scss" scoped>
.disable-step {
    background-color: #50585c;
    border-color: #50585c;
}
</style>
