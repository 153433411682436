import moment from 'moment';
import i18n from '@/i18n';
import { settingPhoneService } from '@/helpers/fakebackend/setting-phone.service';
import { partnersService } from '@/helpers/fakebackend/partners.service';
import {
    STATUS_WORKFLOW,
    ACTION_TYPE,
    PHONE_NUMBER_TYPE,
    OPTIONS_PARTNERS_DEFAULT,
    WORKFLOW_TYPE,
    OPTIONS_WORKFLOWS,
    SETTING_CONTENT_TYPES
} from './constants';
import { formatDateJP } from '@/utils/format';
import { state } from '@/state/modules/authfack.js';

function FORM_INPUT_COMMON() {
    return {
        startOne: {
            id_phone_setting: '',
            campaign_id: '',
            campaign: '',
            errorCampaign: '',
            configSelectCampaign: {
                trackBy: 'id',
                label: 'name',
                error: false,
                isRemove: true
            },
            filling_date: new Date().toISOString().substr(0, 10),
            errorFillingDate: '',
            configFilling_Date: {
                error: false
            },
            role_id: '',
            optionsDepartment: [
                {
                    id: 2,
                    name: i18n.t('settingPhone.department1')
                },
                {
                    id: 3,
                    name: i18n.t('settingPhone.department2')
                }
            ],
            configDepartment: {
                multiple: false,
                trackBy: 'id',
                label: 'name',
                error: false
            },
            sale_staff: '',
            person_in_charge: '',
            filling_by: '',
            errorFillingBy: '',
            phone_setting_start_date: '',
            phone_setting_end_date: '',
            phone_setting_start_time: '',
            phone_setting_end_time: '',
            configPhoneSettingStartDate: {
                error: false
            },
            errorPhoneSettingStartDate: '',
            configPhoneSettingEndDate: {
                error: false
            },
            errorPhoneSettingEndDate: '',
            phone_number_type: '',
            configPhoneNumberType: {
                trackBy: 'id',
                label: 'name',
                error: false,
                isRemove: true
            },
            desired_release_date: '',
            desired_release_date_time: '',
            configDesiredReleaseDate: { minuteStep: 1, placeholderTime: '', error: false },
            errorDesiredReleaseDate: '',
            desired_open_phone_date: '',
            configDesiredOpenPhoneDate: {
                error: false
            },
            errorDesiredOpenPhoneDate: '',
            pre_order_phone_numbers: '',
            reservation: '',
            planning_to_set_up: '2023/●/●（●）開設工事予定',
            has_connection: {
                id: 0,
                name: i18n.t('settingPhone.hasconnectionNo')
            },
            configConnection: {
                trackBy: 'id',
                label: 'name',
                error: false
            },
            number_of_connection: '',
            order_number: null,
            optionsOrderNumber: [
                {
                    id: 1,
                    name: i18n.t('settingPhone.department1')
                },
                {
                    id: 2,
                    name: i18n.t('settingPhone.department2')
                }
            ],
            configOrderNumber: {
                multiple: false,
                trackBy: 'id',
                label: 'name',
                error: false
            },
            call_success: null,
            optionsCallSuccess: [
                {
                    id: 3,
                    name: i18n.t('settingPhone.department1')
                },
                {
                    id: 4,
                    name: i18n.t('settingPhone.department2')
                }
            ],
            configCallSuccess: {
                multiple: false,
                trackBy: 'id',
                label: 'name',
                error: false
            },
            instructor: null,
            optionsInstructor: [
                {
                    id: 5,
                    name: i18n.t('settingPhone.department1')
                },
                {
                    id: 6,
                    name: i18n.t('settingPhone.department2')
                }
            ],
            configInstructor: {
                multiple: false,
                trackBy: 'id',
                label: 'name',
                error: false
            },
            close_order: null,
            optionsCloseOrder: [
                {
                    id: 7,
                    name: i18n.t('settingPhone.department1')
                },
                {
                    id: 8,
                    name: i18n.t('settingPhone.department2')
                }
            ],
            configCloseOrder: {
                multiple: false,
                trackBy: 'id',
                label: 'name',
                error: false
            },
            desired_date_to_end_the_call: '',
            configDesiredDateToEndTheCall: {
                error: false,
                errorDesiredDateToEndTheCall: ''
            },
            setting_details: '',
            workflow_type: null,
            configWorkflowTypeCommon: {
                multiple: false,
                isRemove: true,
                trackBy: 'id',
                label: 'name',
                error: false,
                errorText: ''
            },
            optionsWorkflowTypeCommon: OPTIONS_WORKFLOWS,
            partner: null,
            optionsPartnerSettingPhone: [],
            configPartner: {
                trackBy: 'id',
                label: 'name',
                error: false,
                isRemove: true,
                loading: false,
                errorPartner: ''
            },
            setting_content_type: null,
            setting_content_data: '',
            configSettingContentType: {
                trackBy: 'id',
                label: 'name',
                error: false,
                isRemove: false,
                loading: false,
                errorPartner: ''
            }
        },
        startFive: {
            note: ''
        }
    };
}

function SETTING_PHONE_WORK_COMMON() {
    return [
        {
            parent_id: 1,
            label: i18n.t('settingPhone.settingOpenInteral'),
            headWork: []
        },
        {
            parent_id: 2,
            label: i18n.t('settingPhone.settingCloseInteral'),
            headWork: []
        }
    ];
}

async function getUserInRoom(payload) {
    try {
        const response = await settingPhoneService.getListUserInRoom(payload);
        return response.data;
    } catch (error) {
        console.error('Error getting user list:', error);
        return [];
    }
}
async function getListPartnersSettingPhone() {
    try {
        const response = await partnersService.getPartnersSettingPhone();
        return response?.data ?? [];
    } catch (error) {
        console.error('Error getting partner list:', error);
        return [];
    }
}

function handleDisabledStep(newData, isRegister, isEdit) {
    let currentStep = null;
    newData.forEach((head) => {
        if (isRegister) {
            head.isDisabledStep = true;
            return;
        }
        if (isEdit) {
            head.isDisabledStep = true;
            return;
        }
        if (head.status === STATUS_WORKFLOW[0].id) {
            let userApproveInternal = [];
            let userApprovePartner = [];
            if (currentStep === null && head.in_order) {
                currentStep = head.child_id;
            }
            let listUserApprove = ACTION_TYPE.find((item) => item.id === head.action_user_type);
            if (listUserApprove.user.length > 0) {
                userApproveInternal = listUserApprove.user.map((item) => item?.id) ?? [];
            } else if (listUserApprove.partner_pic.length > 0) {
                userApprovePartner = listUserApprove.partner_pic.map((item) => item?.id) ?? [];
            }

            if (head.in_order) {
                if (currentStep === head.child_id) {
                    if (userApproveInternal.length > 0) {
                        head.isDisabledStep = userApproveInternal.includes(state?.user?.id) || state?.user?.role?.id === 1 ? false : true;
                    } else if (userApprovePartner.length > 0) {
                        head.isDisabledStep = userApprovePartner.partner_pic.includes(state?.user?.id) || state?.user?.role?.id === 1 ? false : true;
                    } else {
                        head.isDisabledStep = state?.user?.role?.id === 1 ? false : true;
                    }
                }
            } else {
                if (userApproveInternal.length > 0) {
                    head.isDisabledStep = userApproveInternal.includes(state?.user?.id) || state?.user?.role?.id === 1 ? false : true;
                } else if (userApprovePartner.length > 0) {
                    head.isDisabledStep = userApprovePartner.partner_pic.includes(state?.user?.id) || state?.user?.role?.id === 1 ? false : true;
                } else {
                    head.isDisabledStep = state?.user?.role?.id === 1 ? false : true;
                }
            }
        } else if (head.status === STATUS_WORKFLOW[1].id) {
            //1: step is approved
            if (head.in_order) {
                head.isDisabledStep = true;
            } else {
                head.isDisabledStep = head.status === STATUS_WORKFLOW[1].id;
            }
        }
    });
}

function handleActiveStepCurrent(settingPhoneWork, activeStepCurrent, initForm, data, activeTabFollowStep) {
    if (settingPhoneWork.length > 0) {
        activeStepCurrent = null;
        settingPhoneWork.forEach((item) => {
            if (activeStepCurrent === null && item.status === 0 && item.in_order) {
                activeStepCurrent = item.child_id;
                activeTabFollowStep = item.tab_type === 1 ? 0 : 1;
            }
            mapDataResponse(initForm, item, data);
        });
    }
    return { activeStepCurrent, activeTabFollowStep };
}

function handleMapDataInitForm(initForm, data, activeStepCurrent, settingPhoneWork, activeTabFollowStep) {
    let { startOne, startFive } = initForm;
    const objPhoneRes = data.phone_setting;
    const dataWorklowed = data.workflows;

    if (objPhoneRes && objPhoneRes.id) {
        startOne.id_phone_setting = objPhoneRes.id ?? '';
        startOne.role_id = objPhoneRes.role_id ? initForm.startOne.optionsDepartment.find((item) => item.id === objPhoneRes.role_id) : null;
        startOne.filling_by = objPhoneRes.filling_by ?? '';
        startOne.phone_number_type = objPhoneRes.phone_number_type
            ? PHONE_NUMBER_TYPE.find((item) => item.id === objPhoneRes.phone_number_type)
            : null;
        startOne.desired_open_phone_date = objPhoneRes.desired_open_phone_date ?? '';
        startOne.pre_order_phone_numbers = objPhoneRes.pre_order_phone_numbers ?? '';
        startOne.reservation = objPhoneRes.reservation ?? '';
        startOne.planning_to_set_up = objPhoneRes.planning_to_set_up ?? '';
        startOne.has_connection = objPhoneRes.has_connection ?? '';
        startOne.number_of_connection = objPhoneRes.number_of_connection ?? '';
        startOne.desired_date_to_end_the_call = objPhoneRes.desired_date_to_end_the_call ?? '';
        startOne.setting_details = objPhoneRes.setting_details ?? '';
        startOne.partner = objPhoneRes.partner ?? null;
        startOne.workflow_type = OPTIONS_WORKFLOWS.find((ow) => ow.id === objPhoneRes.workflow_type) ?? '';
        startOne.setting_content_type = SETTING_CONTENT_TYPES.find((type) => type.id === objPhoneRes.setting_content_type) ?? null;
        startOne.setting_content_data = objPhoneRes.setting_content_data ?? '';

        startFive.note = objPhoneRes.note ?? '';
    }
    // handle activeStepCurrent
    let resultActiveStep = handleActiveStepCurrent(settingPhoneWork, activeStepCurrent, initForm, data);
    activeStepCurrent = resultActiveStep.activeStepCurrent;
    activeTabFollowStep = resultActiveStep.activeTabFollowStep;

    // handle Approval
    if (dataWorklowed && dataWorklowed.length > 0) {
        settingPhoneWork.forEach((itemCombiedHead) => {
            dataWorklowed.forEach((item) => {
                if (itemCombiedHead.child_id === item.step) {
                    itemCombiedHead.status = item.status;
                    itemCombiedHead.confirmed_by = item.confirmed_by;
                    if (item.logs.chatwork && item.logs.chatwork.length > 0) {
                        item.logs.chatwork.forEach((itemChatwork) => {
                            itemCombiedHead.data_chat[0].room_chat = itemChatwork.room;
                            itemCombiedHead.data_chat[0].messageTo = itemChatwork.message?.to ?? [];
                            itemCombiedHead.data_chat[0].messageLog = {
                                body: itemChatwork.message?.body ?? '',
                                image_id: itemChatwork.message?.image_id ?? ''
                            };
                            itemCombiedHead.data_chat[0].taskTo = itemChatwork.task?.to ?? [];
                            itemCombiedHead.data_chat[0].taskLog = {
                                body: itemChatwork.task?.body ?? ''
                            };
                            itemCombiedHead.data_chat[0].due_date = itemChatwork.task?.due_date ?? '';

                            if (item.step == 4) {
                                // lấy ra task của step 発番依頼
                                startOne.desired_release_date_time = itemChatwork.task?.due_date
                                    ? moment(itemChatwork.task.due_date).format('HH:mm')
                                    : '';
                                startOne.desired_release_date = itemChatwork.task?.due_date
                                    ? moment(itemChatwork.task.due_date).format('YYYY-MM-DD')
                                    : '';
                            }

                            const hasAfterSendTo =
                                itemChatwork.message?.to &&
                                itemChatwork.message?.to.length > 0 &&
                                itemChatwork.task?.to &&
                                itemChatwork.task?.to.length > 0;

                            if (hasAfterSendTo) {
                                let uniqueArray = itemChatwork.message?.to
                                    .concat(itemChatwork.task?.to)
                                    .filter(
                                        (value, index, self) =>
                                            self.findIndex(
                                                (v) => v.chatwork_id === value.chatwork_id && v.id === value.id && v.name === value.name
                                            ) === index
                                    );
                                if (itemCombiedHead.data_chat[0] && itemCombiedHead.data_chat[0].afterSendTo) {
                                    itemCombiedHead.data_chat[0].afterSendTo = uniqueArray;
                                }
                            } else {
                                if (itemCombiedHead.data_chat[0] && itemCombiedHead.data_chat[0].afterSendTo) {
                                    itemCombiedHead.data_chat[0].afterSendTo = itemChatwork.message?.to
                                        ? itemChatwork.message?.to
                                        : itemChatwork.task?.to;
                                }
                            }
                        });
                    }
                }
            });
        });

        let updateStepAfterApprove = handleActiveStepCurrent(settingPhoneWork, activeStepCurrent, initForm, data);
        activeStepCurrent = updateStepAfterApprove.activeStepCurrent;
        activeTabFollowStep = updateStepAfterApprove.activeTabFollowStep;
    }
    return { activeStepCurrent, activeTabFollowStep };
}

function mapDataResponse(initForm, dataLocal, dataAPI) {
    const { phone_setting } = dataAPI;
    const campaign = initForm.inforCampaign;
    dataLocal.shortCode = handleDataShortCode(phone_setting, campaign);
}

function handleSetConfigTemplate(dataArray, settingPhoneWork, hasPhoneSetting, workflowType = '', dataPhoneSetting = null) {
    // reset headWork before push
    settingPhoneWork = settingPhoneWork.map((item) => {
        item.headWork = [];
        return item;
    });

    if (WORKFLOW_TYPE.PARTNER == workflowType) {
        const settingPhoneWorkPartner = OPTIONS_PARTNERS_DEFAULT.find(
            (item) => item?.name.trim().toString().toLowerCase() === dataPhoneSetting?.phone_setting?.partner?.name.trim().toString().toLowerCase()
        );
        // set template default
        if (!settingPhoneWorkPartner) {
            return setTemplateDefault(dataArray, settingPhoneWork);
        }
        dataArray.forEach((itemArray) => {
            if (itemArray.tab_type === settingPhoneWork[0].parent_id) {
                settingPhoneWork[0].headWork.push({
                    child_id: itemArray.step,
                    label: itemArray.name,
                    action_user_type: itemArray.action_user_type,
                    status: 0,
                    data_chat: itemArray.default_config
                        .filter((itemDFConfig) => settingPhoneWorkPartner.step_default.includes(itemDFConfig.data_index))
                        .map((itemAfterFil) => ({
                            data_index: itemAfterFil.data_index,
                            id_chat: null,
                            room_chat: {
                                id: itemAfterFil.room_id ? Number(itemAfterFil.room_id) : null,
                                name: itemAfterFil.room_name ?? ''
                            },
                            optionsRoomChat: [
                                {
                                    id: itemAfterFil.room_id ? Number(itemAfterFil.room_id) : null,
                                    name: itemAfterFil.room_name ?? ''
                                }
                            ],
                            details: {
                                message: itemAfterFil.message?.msg ?? '',
                                task: itemAfterFil.task?.msg ?? ''
                            },
                            configMess: {
                                to_person_in_charge: itemAfterFil.message?.to_person_in_charge ?? false,
                                to_users: itemAfterFil.message?.to_users ?? [],
                                to_in_charge_of_distribution: itemAfterFil.message?.to_in_charge_of_distribution ?? false,
                                to_phone_setting_construction: itemAfterFil.message?.to_phone_setting_construction ?? false
                            },
                            configTask: {
                                to_person_in_charge: itemAfterFil.task?.to_person_in_charge ?? false,
                                to_users: itemAfterFil.task?.to_users ?? [],
                                to_in_charge_of_distribution: itemAfterFil.task?.to_in_charge_of_distribution ?? false,
                                to_phone_setting_construction: itemAfterFil.task?.to_phone_setting_construction ?? false
                            },
                            afterSendTo: []
                        })),
                    backgroundColor: ACTION_TYPE.find((itemAction) => itemAction.id === itemArray.action_user_type)?.backgroundColor,
                    isDisabledStep: true,
                    isRoomChat: !hasPhoneSetting
                        ? true
                        : itemArray.default_config
                              .filter((itemDFConfig) => settingPhoneWorkPartner.step_default.includes(itemDFConfig.data_index))
                              .some((itemAfterFil) => itemAfterFil.room_id && itemAfterFil.room_name),
                    sendBoth: !hasPhoneSetting ? true : itemArray.send_type === 3 ? true : false,
                    tab_type: itemArray.tab_type,
                    phoneNumberRequired: itemArray?.phone_number_require ?? false,
                    confirm_msg: itemArray?.confirm_msg ?? null,
                    shortCode: null
                });
            } else if (settingPhoneWork[1].parent_id) {
                settingPhoneWork[1].headWork.push({
                    child_id: itemArray.step,
                    label: itemArray.name,
                    action_user_type: itemArray.action_user_type,
                    status: 0,
                    data_chat: itemArray.default_config
                        .filter((itemDFConfig) => settingPhoneWorkPartner.step_default.includes(itemDFConfig.data_index))
                        .map((itemAfterFil) => ({
                            data_index: itemAfterFil.data_index,
                            id_chat: null,
                            room_chat: {
                                id: itemAfterFil.room_id ? Number(itemAfterFil.room_id) : null,
                                name: itemAfterFil.room_name ?? ''
                            },
                            optionsRoomChat: [
                                {
                                    id: itemAfterFil.room_id ? Number(itemAfterFil.room_id) : null,
                                    name: itemAfterFil.room_name ?? ''
                                }
                            ],
                            details: {
                                message: itemAfterFil.message?.msg ?? '',
                                task: itemAfterFil.task?.msg ?? ''
                            },
                            configMess: {
                                to_person_in_charge: itemAfterFil.message?.to_person_in_charge ?? false,
                                to_users: itemAfterFil.message?.to_users ?? [],
                                to_in_charge_of_distribution: itemAfterFil.message?.to_in_charge_of_distribution ?? false,
                                to_phone_setting_construction: itemAfterFil.message?.to_phone_setting_construction ?? false
                            },
                            configTask: {
                                to_person_in_charge: itemAfterFil.task?.to_person_in_charge ?? false,
                                to_users: itemAfterFil.task?.to_users ?? [],
                                to_in_charge_of_distribution: itemAfterFil.task?.to_in_charge_of_distribution ?? false,
                                to_phone_setting_construction: itemAfterFil.task?.to_phone_setting_construction ?? false
                            },
                            afterSendTo: []
                        })),
                    backgroundColor: ACTION_TYPE.find((itemAction) => itemAction.id === itemArray.action_user_type)?.backgroundColor,
                    isDisabledStep: true,
                    isRoomChat: !hasPhoneSetting
                        ? true
                        : itemArray.default_config
                              .filter((itemDFConfig) => settingPhoneWorkPartner.step_default.includes(itemDFConfig.data_index))
                              .some((itemAfterFil) => itemAfterFil.room_id && itemAfterFil.room_name),
                    sendBoth: !hasPhoneSetting ? true : itemArray.send_type === 3 ? true : false,
                    tab_type: itemArray.tab_type,
                    phoneNumberRequired: itemArray?.phone_number_require ?? false,
                    confirm_msg: itemArray?.confirm_msg ?? null,
                    shortCode: null
                });
            }
        });
    } else {
        settingPhoneWork = setTemplateDefault(dataArray, settingPhoneWork, dataPhoneSetting);
    }
    return settingPhoneWork;
}

function setTemplateDefault(dataArray, settingPhoneWork, dataPhoneSetting) {
    dataArray.forEach((itemArray) => {
        if (itemArray.tab_type === settingPhoneWork[0].parent_id) {
            settingPhoneWork[0].headWork.push({
                in_order: itemArray.in_order,
                child_id: itemArray.step,
                label: itemArray.name,
                action_user_type: itemArray.action_user_type,
                status: 0,
                data_chat:
                    itemArray.default_config.map((itemDFConfig) => {
                        return {
                            data_index: itemDFConfig.data_index,
                            id_chat: null,
                            room_chat: {
                                id: itemDFConfig.room_id ? Number(itemDFConfig.room_id) : null,
                                name: itemDFConfig.room_name ?? ''
                            },
                            optionsRoomChat: [
                                {
                                    id: itemDFConfig.room_id ? Number(itemDFConfig.room_id) : null,
                                    name: itemDFConfig.room_name ?? ''
                                }
                            ],
                            details: {
                                message: itemDFConfig.message?.msg ?? '',
                                task: itemDFConfig.task?.msg ?? ''
                            },
                            configMess: {
                                to_person_in_charge: itemDFConfig.message?.to_person_in_charge ?? false,
                                to_users: itemDFConfig.message?.to_users ?? [],
                                to_in_charge_of_distribution: itemDFConfig.message?.to_in_charge_of_distribution ?? false,
                                to_phone_setting_construction: itemDFConfig.message?.to_phone_setting_construction ?? false
                            },
                            configTask: {
                                to_person_in_charge: itemDFConfig.task?.to_person_in_charge ?? false,
                                to_users: itemDFConfig.task?.to_users ?? [],
                                to_in_charge_of_distribution: itemDFConfig.task?.to_in_charge_of_distribution ?? false,
                                to_phone_setting_construction: itemDFConfig.task?.to_phone_setting_construction ?? false
                            },
                            afterSendTo: []
                        };
                    }) ?? [],
                backgroundColor: ACTION_TYPE.find((itemAction) => itemAction.id === itemArray.action_user_type)?.backgroundColor,
                isDisabledStep: true,
                isRoomChat: itemArray.default_config.some((itemDefaultConfig) => itemDefaultConfig.room_id && itemDefaultConfig.room_name),
                sendBoth: itemArray.send_type === 3 ? true : false,
                tab_type: itemArray.tab_type,
                // 発番完了 (5)
                phoneNumberRequired:
                    itemArray.step == 5
                        ? dataPhoneSetting?.phone_setting?.pre_order_phone_numbers || dataPhoneSetting?.phone_setting?.reservation
                            ? false
                            : true
                        : false,
                confirm_msg: itemArray?.confirm_msg ?? null,
                shortCode: null
            });
        } else if (itemArray.tab_type === settingPhoneWork[1].parent_id) {
            settingPhoneWork[1].headWork.push({
                in_order: itemArray.in_order,
                child_id: itemArray.step,
                label: itemArray.name,
                action_user_type: itemArray.action_user_type,
                status: 0,
                data_chat:
                    itemArray.default_config.map((itemDFConfig) => {
                        return {
                            data_index: itemDFConfig.data_index,
                            id_chat: null,
                            room_chat: {
                                id: itemDFConfig.room_id ? Number(itemDFConfig.room_id) : null,
                                name: itemDFConfig.room_name ?? ''
                            },
                            optionsRoomChat: [
                                {
                                    id: itemDFConfig.room_id ? Number(itemDFConfig.room_id) : null,
                                    name: itemDFConfig.room_name ?? ''
                                }
                            ],
                            details: {
                                message: itemDFConfig.message?.msg ?? '',
                                task: itemDFConfig.task?.msg ?? ''
                            },
                            configMess: {
                                to_person_in_charge: itemDFConfig.message?.to_person_in_charge ?? false,
                                to_users: itemDFConfig.message?.to_users ?? [],
                                to_in_charge_of_distribution: itemDFConfig.message?.to_in_charge_of_distribution ?? false,
                                to_phone_setting_construction: itemDFConfig.message?.to_phone_setting_construction ?? false
                            },
                            configTask: {
                                to_person_in_charge: itemDFConfig.task?.to_person_in_charge ?? false,
                                to_users: itemDFConfig.task?.to_users ?? [],
                                to_in_charge_of_distribution: itemDFConfig.task?.to_in_charge_of_distribution ?? false,
                                to_phone_setting_construction: itemDFConfig.task?.to_phone_setting_construction ?? false
                            },
                            afterSendTo: []
                        };
                    }) ?? [],
                backgroundColor: ACTION_TYPE.find((itemAction) => itemAction.id === itemArray.action_user_type)?.backgroundColor,
                isDisabledStep: true,
                isRoomChat: itemArray.default_config.some((itemDefaultConfig) => itemDefaultConfig.room_id && itemDefaultConfig.room_name),
                sendBoth: itemArray.send_type === 3 ? true : false,
                tab_type: itemArray.tab_type,
                phoneNumberRequired: itemArray?.phone_number_require ?? false,
                confirm_msg: itemArray?.confirm_msg ?? null,
                shortCode: null
            });
        }
    });
    return settingPhoneWork;
}

function setDataConfigForDocument(dataRespones, currentUser) {
    let userRoleOrange = [...dataRespones.responsible_sub_persons, dataRespones.person_in_charge].map((item) => item?.id) ?? [];

    return {
        phone_setting: dataRespones.phone_setting ?? null,
        idPhoneSetting: dataRespones.phone_setting?.id ?? null,
        campaign: {
            id: dataRespones.id ?? null,
            name: dataRespones.name ?? ''
        },
        sale_staff: dataRespones.sale_staff?.name ?? '',
        isEdit: userRoleOrange.includes(currentUser.id) || state.user?.role?.id === 1 ? false : true,
        isRegister: !dataRespones.phone_setting,
        person_in_charge: dataRespones.person_in_charge ?? null,
        customer_team_pic: dataRespones.customer_team_pic ?? null,
        phone_pic: dataRespones.phone_pic ?? null,
        partner_pic: dataRespones?.phone_setting?.partner_pic ?? [],
        workflow_type: dataRespones.phone_setting?.workflow_type ?? null,
        userRoleDirection: [...dataRespones.responsible_sub_persons, dataRespones.person_in_charge],
        inforCampaign: {
            id: dataRespones.id ?? null,
            name: dataRespones.name ?? '',
            sale_staff: dataRespones.sale_staff?.name ?? '',
            person_in_charge: dataRespones.person_in_charge ?? '',
            customer_team_pic: dataRespones.customer_team_pic ?? '',
            phone_pic: dataRespones.phone_pic ?? '',
            clients: dataRespones.clients ?? '',
            agencies: dataRespones.agencies ?? '',
            phone_setting_start_time: dataRespones.secretariat_start_date ? moment(dataRespones.secretariat_start_date).format('HH:mm') : '',
            phone_setting_end_time: dataRespones.secretariat_end_date ? moment(dataRespones.secretariat_end_date).format('HH:mm') : '',
            phone_setting_start_date: dataRespones.secretariat_start_date ? moment(dataRespones.secretariat_start_date).format('YYYY-MM-DD') : '',
            phone_setting_end_date: dataRespones.secretariat_end_date ? moment(dataRespones.secretariat_end_date).format('YYYY-MM-DD') : '',
            secretariat_start_date: dataRespones.secretariat_start_date ?? '',
            secretariat_end_date: dataRespones.secretariat_end_date ?? '',
            total_number_of_win_prize: dataRespones.total_number_of_win_prize ?? '',
            person_in_charge_instructions: dataRespones.person_in_charge_instructions ?? '',
            url_campaign: ``,
            url_phone_setting: window.location.href,
            desired_release_date: '',
            phone_number_register_campaign: '',
            chat_room: dataRespones.chatwork_rooms ?? [],
            campaign_master_order_scopes: dataRespones.campaign_master_order_scopes ?? [],
            campaign_master_order_scope_partner: dataRespones.campaign_master_order_scope_partner ?? [],
            categories: dataRespones.categories?.map((ct) => ct.category.value).join('、') ?? '',
            campaign_start_date: dataRespones.campaign_start_date ?? '',
            campaign_end_date: dataRespones.campaign_end_date ?? ''
        }
    };
}

function handleDataShortCode(phone_setting, campaign) {
    const nameWorkFlow =
        phone_setting.workflow_type !== null
            ? phone_setting?.workflow_type == 1
                ? i18n.t('document.internal')
                : phone_setting?.partner?.name ?? ''
            : '';
    return {
        name_campaign: phone_setting?.campaign?.name ?? campaign.name,
        name_client_and_agency:
            `${campaign.clients.map((client) => client.name).join('、')} / ${campaign.agencies.map((agencie) => agencie.name).join('、')}` ?? '',

        phone_setting_start_time: phone_setting.phone_setting_start_date
            ? moment(phone_setting.phone_setting_start_date).format('HH:mm')
            : campaign.secretariat_start_date
              ? moment(campaign.secretariat_start_date).format('HH:mm')
              : '',
        phone_setting_end_time: phone_setting.phone_setting_end_date
            ? moment(phone_setting.phone_setting_end_date).format('HH:mm')
            : campaign.secretariat_end_date
              ? moment(campaign.secretariat_end_date).format('HH:mm')
              : '',
        phone_setting_start_date: phone_setting.phone_setting_start_date
            ? formatDateJP(moment(phone_setting.phone_setting_start_date).format('YYYY-MM-DD'))
            : campaign.secretariat_start_date
              ? formatDateJP(moment(campaign.secretariat_start_date).format('YYYY-MM-DD'))
              : '',
        phone_setting_end_date: phone_setting.phone_setting_end_date
            ? formatDateJP(moment(phone_setting.phone_setting_end_date).format('YYYY-MM-DD'))
            : campaign.secretariat_end_date
              ? formatDateJP(moment(campaign.secretariat_end_date).format('YYYY-MM-DD'))
              : '',
        secretariat_start_time: campaign.secretariat_start_date ? moment(campaign.secretariat_start_date).format('HH:mm') : '',
        secretariat_end_time: campaign.secretariat_end_date ? moment(campaign.secretariat_end_date).format('HH:mm') : '',
        secretariat_start_date: campaign.secretariat_start_date ? formatDateJP(moment(campaign.secretariat_start_date).format('YYYY-MM-DD')) : '',
        secretariat_end_date: campaign.secretariat_end_date ? formatDateJP(moment(campaign.secretariat_end_date).format('YYYY-MM-DD')) : '',
        campaign_start_time: campaign.campaign_start_date ? moment(campaign.campaign_start_date).format('HH:mm') : '',
        campaign_end_time: campaign.campaign_end_date ? moment(campaign.campaign_end_date).format('HH:mm') : '',
        campaign_start_date: campaign.campaign_start_date ? formatDateJP(moment(campaign.campaign_start_date).format('YYYY-MM-DD')) : '',
        campaign_end_date: campaign.campaign_end_date ? formatDateJP(moment(campaign.campaign_end_date).format('YYYY-MM-DD')) : '',
        total_number_of_win_prize: campaign.total_number_of_win_prize ?? '',
        person_in_charge: campaign.person_in_charge?.name ?? '',
        sale_staff: phone_setting?.sale_staff?.name ?? campaign.sale_staff ?? '',
        phone_number_register_campaign: phone_setting.phone_number_forward ?? '',
        person_in_charge_instructions: campaign.person_in_charge?.name ?? '',
        url_campaign: ``,
        url_phone_setting: campaign.url_phone_setting,
        pre_order_phone_numbers: phone_setting.pre_order_phone_numbers ?? '',
        reservation: phone_setting.reservation ?? '',
        categories: campaign.categories ?? '',
        phone_number_type: PHONE_NUMBER_TYPE.find((item) => item.id === phone_setting.phone_number_type)?.name ?? '',
        name_workflow: nameWorkFlow
    };
}

export {
    FORM_INPUT_COMMON,
    SETTING_PHONE_WORK_COMMON,
    getUserInRoom,
    handleDisabledStep,
    handleMapDataInitForm,
    handleSetConfigTemplate,
    getListPartnersSettingPhone,
    setDataConfigForDocument
};
